body {
    font-family: 'Montserrat', sans-serif;
    font-size:14px;
}

.customSelect {
    position: relative;
    cursor: pointer;
}

.customSelect select {
    height: 45px;
    padding-right: 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

.customSelect:after {
    font-family: 'FontAwesome';
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 6px;
    color: #00235D;
    background: white;
    font-weight: 900;
    font-size: 24px;
    pointer-events: none;
}

.selectLabel {
    color: #3f4454;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom:5px;
}

.header {
    background-image: url(Images/header.png);
}

.resultsTable {
    width: 100%;
    border: 1px solid #00235d;
}

.resultsTable tr td {
    padding: 5px;
    color: #666666;
}

.resultsTable tr th {
    padding: 5px;
}

.resultsTable thead tr {
    background-color: #00235d;
}

.resultsTable tbody tr:nth-child(even) {
    background-color: #efefef;
}

.btnSort {
    padding: 0px;
    font-weight: bold;
    color: white;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer !important;
}

.btnSort:hover {
    color: white;
    cursor: pointer !important;
}

.btnBack {
    color: #3f4454;
    font-weight:bold;
    padding:0px;
    cursor:pointer !important;
}

.btnBack:hover {
    border-bottom: 1px solid #3f4454;
    cursor: pointer !important;
}

.btnBack path {
    color: #3f4454;
}

h1 {
    color: #666666;
    letter-spacing: 2px;
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
}

h3 {
    color: #3f4454;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
}

h4 {
    font-size: 16px;
    font-weight: 600;
    color: #666666;
}

.grayLink {
    color: #666666;
    text-decoration:underline;
}

.grayLink:hover {
    color: #666666;
    cursor:pointer;
}

.center{
    text-align:center;
}

@media (max-width: 576px) {
    #dvAthleteGraphDropDown {
        margin-left: 0px !important;
    }
}

